import {
  getMetadata,
  getMetadataAuthorized,
  ModelMetadata,
  DisplayMode,
  ProductCategory,
} from '@wearitar/model-display-tools';
import { getUrl } from '../api/model/getUrl';
import { useEffect } from 'react';
import { useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { isEditor } from '../utils/isEditor';

export const useMetadata = (modelId: string) => {
  const [metadata, setMetadata] = useState<ModelMetadata>({
    modelId: modelId || '',
    displayMode: DisplayMode.viewer, // Let's viewer be default.
    name: '',
    modelPath: '',
    productCategory: ProductCategory.General,
    size: 0,
    ar: {
      hdrUrl: '/assets/textures/Proper.hdr',
      rotation: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    viewer: {
      disablePresets: false,
      presets: [],
      hdrUrl: '/assets/textures/Proper.hdr',
    },
    thumbnailUrl: '',
  });
  const handleError = useErrorHandler();

  useEffect(() => {
    (async () => {
      if (!modelId) {
        throw new Error('modelId has to be present in URL params');
      }

      const metadataFromApi = isEditor()
        ? await getMetadataAuthorized(
            modelId,
            process.env.REACT_APP_API_ENDPOINT!
          )
        : await getMetadata(modelId, process.env.REACT_APP_API_ENDPOINT!);

      setMetadata({
        ...metadata,
        modelPath: getUrl(modelId),
        ...metadataFromApi,
      });
    })().catch((error) => {
      // 40x
      if (error.code === 'ERR_BAD_REQUEST') {
        return handleError(
          new Error("AR Experience you requested doesn't exist")
        );
      }
      if (typeof error?.response?.data === 'string') {
        return handleError(new Error(error?.response?.data));
      }
      return handleError(error);
    });
  }, [modelId, handleError]);

  return metadata;
};
