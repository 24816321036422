import { createContext, useContext, PropsWithChildren } from 'react';
import { usePreloadThumbnails } from '../hooks/usePreloadThumbnails'; // Assuming the hook is in a separate file
import { ModelMetadata } from '@wearitar/model-display-tools';

type ImageContextType = {
  images: Record<string, string>;
  isDefaultPreloaded: boolean;
  isFullyPreloaded: boolean;
  shouldPreload: boolean;
};

export const PreloadedImageContext = createContext<ImageContextType>({
  images: {},
  isDefaultPreloaded: false,
  isFullyPreloaded: false,
  shouldPreload: true,
});

export const useImages = (): ImageContextType =>
  useContext(PreloadedImageContext);

type ImageContextProviderProps = {
  metadata: ModelMetadata;
};

export const ImageContextProvider = ({
  metadata,
  children,
}: ImageContextProviderProps & PropsWithChildren) => {
  const { images, isDefaultPreloaded, isFullyPreloaded, shouldPreload } =
    usePreloadThumbnails(metadata);

  return (
    <PreloadedImageContext.Provider
      value={{ images, isDefaultPreloaded, isFullyPreloaded, shouldPreload }}
    >
      {children}
    </PreloadedImageContext.Provider>
  );
};
